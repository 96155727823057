<template>
  <div id="voting-rights">
    <v-card>
      <v-card-title
        ><h3>{{ PageTitle }}</h3>
        <v-spacer />
        <v-btn
          @click.prevent="pageData"
          color="#a4c639"
          min-width="92"
          rounded
          mediums
          class="font-size-h6 px-3 py-3 ml-3 white--text"
        >
          Refresh
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row wrap>
          <v-col cols="12" lg="4" md="6" sm="6">
            <div id="card-body">
              <v-card id="card">
                <v-card-text>
                  <h2>SS Card</h2>
                  <hr />
                  <br />
                  <span class="text-h5">Status : </span>

                  <v-chip
                    draggable
                    dark
                    class="text-h6 px-5 py-5 ml-4"
                    :color="getActiveStatusColor(VotingRightsStatus)"
                    text-color="white"
                  >
                    {{ VotingRightsStatus }}
                  </v-chip>
                  <br />
                  <div v-if="VotingRightsStatus != 'Not completed'">
                    <br />
                    <span class="text-h5">Document : </span>
                    <a :href="DocumentPath" target="_blank" class="text-h5 ml-4"
                      >Download</a
                    >
                  </div>
                  <div v-else><br /><br /></div>
                  <br />
                  <div align="center">
                    <v-btn
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                      @click.prevent="uploadDocumentPrompt = true"
                      v-if="VotingRightsStatus != 'Approved'"
                    >
                      Upload
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="6">
            <div id="card-body">
              <v-card id="card">
                <v-card-text>
                  <h2>Eligible MemberShip For Voting Rights</h2>
                  <hr />
                  <br />
                  <h3 align="center">Eligible Count</h3>
                  <br />
                  <div align="center">
                    <v-chip
                      draggable
                      dark
                      class="text-h6 px-5 py-5 ml-4"
                      color="black"
                    >
                      <h5>{{ EligibleCount }}</h5>
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <br />
      </v-card-text>
    </v-card>
    <v-dialog v-model="uploadDocumentPrompt" persistent max-width="70%">
      <voting-rights-create
        :addRecordPrompt="uploadDocumentPrompt"
        @hideAddRecordPrompt="hideUploadRecordPrompt"
        v-if="uploadDocumentPrompt"
      ></voting-rights-create>
    </v-dialog>
  </div>
</template>
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import VotingRightsCreate from "./VotingRightsCreate.vue";

export default {
  mixins: [common],
  components: {
    VotingRightsCreate,
  },
  data() {
    return {
      VotingRightsStatus: "",
      DocumentPath: "",
      uploadDocumentPrompt: false,

      EligibleCount: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath = server_url + "assets/sample/voting_rights.pdf";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;

        this.pageData();
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "voting_rights",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    hideUploadRecordPrompt() {
      console.log("hideUploadRecordPrompt called");
      this.uploadDocumentPrompt = false;
      this.getTableRecords();
    },
    pageData() {
      console.log("pageData called");

      this.getTableRecords();
      this.eligibleMembershipCount();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom-document/show";
      var upload = {
        UserInterface: 3,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          if (flag) {
            var records = response.data.records;
            console.log("records=" + JSON.stringify(records));

            thisIns.VotingRightsStatus = records.LomDocumentStatusTxt;
            thisIns.DocumentPath = records.DocumentAssetPath;

            thisIns.toast("success", output);
          } else {
            thisIns.VotingRightsStatus = "Not completed";
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    eligibleMembershipCount() {
      console.log("eligibleMembershipCount called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom-payment/eligible-count";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          if (flag) {
            var records = response.data.records;
            console.log("records=" + JSON.stringify(records));
            thisIns.EligibleCount = records.EligibleCount;
            thisIns.toast("success", output);
          } else {
            thisIns.VotingRightsStatus = "Not completed";
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#voting-rights {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  #card-body {
    position: relative;
    width: 420px;
    height: 300px;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
      inset -5px -5px 15px #094aee1a, 5px 5px 15px #ffc8dd,
      -5px -5px 15px rgba(150, 5, 5, 0.1);
    border-radius: 15px;
  }
  #card {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: #ffc8dd;
  }
}
</style>